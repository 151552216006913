import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import loadable from "@loadable/component"

import SEO from "../components/seo"
import { setLocation } from "../actions/setLocation"
import SuspenseLoading from "../components/SuspenseLoading"

const HoursLocationPage = loadable(
  () => import("../components/HoursLocationPage/HoursLocationPage"),
  { fallback: <SuspenseLoading /> }
)

const Hours = () => {
  const dispatch = useDispatch()

  //Set the site location.
  useEffect(() => {
    dispatch(setLocation("/hours"))
    return
  }, [dispatch])

  return (
    <>
      <SEO title="Location & Hours" />

      <HoursLocationPage />
    </>
  )
}

export default Hours
